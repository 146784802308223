* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none; }
  
  html,
  body {
  height: 100%;
  font-size: 14px;
  color: #808080;
  font-family: 'Open Sans', sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  }
  a:focus {
    outline: 0px solid;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 0 0 20px;
    text-align: justify;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  margin: 0 0 20px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #292929;
  }
  
  h1 {
  font-size: 32px;
  }
  h2 {
  font-size: 18px;
  }
  h3 {
  font-size: 16px;
  }
  
  a {
    transition: all 0.5s ease-out;
    text-decoration: none;color:#999999
  }
  a:active,
  a:hover {
    outline: 0 none;
    color: #212836;text-decoration:none
  }
  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
  }
  
  ::-moz-selection {
    background: #07358B;
    text-shadow: none;color:#fff
  }
  ::selection {
    background: #07358B;
    text-shadow: none;color:#fff
  }
  textarea:focus, input:focus{
      outline: none;
  }
  .slider-overlay {
    background-color:rgba(0, 0, 0, 0.30);
    height: 100%;  width: 100%;
    position: absolute;
    top: 0;z-index:1
  }
  .images-overlay {
     background-color: rgba(0, 0, 0, 0.70);
    height: 100%;
    position: absolute;  width: 100%;
    top: 0;
  
  }
  
  .why-choose-single{ font-style: italic; }
  
  .pt-30{ padding-top: 30px; padding-bottom: 30px; }
  .pt-100{padding-top:100px}
  .pb-100{padding-bottom:100px}
  .pb-70{padding-bottom:70px}
  .bg-color{background:#fbfbfb}
  .no-padding-left{padding-left:0px}
  .no-padding-right{padding-right:0px}
  
  .footer-sec a{ color: #b0acac; }
  
  /*
  1.Preloader
  ============================*/
  #preloader {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 9999999;
      right: 0;
      bottom: 0;
  }
  #preloader-status {
      height: 210px;
      width: 210px;
      /* background: url(../img/preloader.gif); */
      background-repeat: no-repeat;
      background-position: center;
      margin: -100px 0 0 -100px;
      position: absolute;
      left: 50%;
      top: 50%;	
  }
  
  /*
  2.Scrollup
  ==============*/
  #scrollUp {
      background: #07358B none repeat scroll 0 0;
      border-radius: 100%;
      bottom: 5px;
      color: #fff;
      font-size:16px;
      height: 40px;
      right: 20px;
      text-align: center;
      text-decoration: none;
      width: 40px;
  }
  #scrollUp:hover {
    opacity: 1;
    color: #fff!important;
  }
  #scrollUp i {
  display: block;
  font-weight: bold;
  padding-top: 11px;
  }
  
  /*
  3.Header Section
  ==================*/
  .header-top {
      background: #242424;
  }
  .header-left {
      padding: 10px 0;
  }
  .header-left ul {
      display: inline-block;
  }
  .header-left li{padding-right:30px;position:relative;display: inline-block;color: #fff;}
  .header-left li:last-child:before{content:""}
  .header-left li::before {
    content: "/";
    position: absolute;
    right:12px;
  }
  .header-left li i {
      color: #858484;
      font-size: 20px;
      margin-right: 10px;
  }
  .header-right {
      text-align: center;position:relative;z-index:1;	padding: 10px 0;
  }
  
  .about-sec p{ text-align: justify; }
  
  .header-right::before {
      background: #07358B none repeat scroll 0 0;
      content: " ";
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: skewX(-48deg);
      width: 100%;
      z-index: -1;
  }
  .header-right li a {
      color: #ffffff;
      font-weight: 800;
      padding: 0px 10px;text-transform: capitalize;
      font-size: 25px;
  }
  .header-right li{position:relative;display:inline-block}
  .header-right li:last-child:before{content:""}
  .header-right li::before {
    content: "/";
    position: absolute;
    right:-6px;color:#fff
  }
  /*
  4.Menu  Section
  =================*/
  .logo {
      padding: 10px 0;
  }
  .logo img{
      position: absolute;
      z-index: 2;
      top: 0;
      width: 160px;
  }
  .menu {
    display: inline-block;
    width: 100%;
  }
  .main-menu {
      display: inline-block;
      text-align: right;
      margin-right: 70px;
  }
  
  .main-menu ul {
  margin : 0;
  padding : 0;
  position : relative;
  }
  .main-menu ul li {
  display: inline-block;
  padding: 0;
  position: relative;
  }
  
  .book_online{
      background: #0657a3;
      color: #fff !important;
      padding: 5px 10px !important;
  }
  
  .book_online:hover{
      background: #07358B;
  }
  
  .about-sec h2{ font-size: 28px; color: #0657a3; }
  
  .btn_sp{
      background: #0657a3;
      color: #fff !important;
      padding: 5px 10px !important;
      font-size: 18px;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 20px;
  }
  
  .main-menu ul li a {
      color: #0657a3;
      display: block;
      font-size: 14px;
      padding: 33px 18px;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.4s ease 0s;
      position: relative;
      font-weight: 600;font-family:Montserrat;
  }
  .main-menu ul li ul:hover .main-menu ul li a{
    background: #fafafa;
  }
  .main-menu ul li a:hover,.main-menu ul li a.active {
  transition : all 0.4s 0s ease;
  color:#ffdc4e
  }
  
  .main-menu ul ul {
  background: #07358B none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  top:92px;
  transition: all 0.4s ease 0s;
  visibility: hidden;
  z-index: 99999;
  transform: scale(0);
  transform-origin: 0 0 0;
  transition: all 0.3s ease 0s;
  }
  .main-menu ul ul li {
  float : none;
  width : 220px;
  }
  .main-menu ul ul li a {
  line-height : 120%;
  padding : 10px 20px;color:#fff;
  }
  .main-menu ul ul li a:hover {
  color:#07358B;background:#fff;
  }
  .main-menu ul ul ul {
  top : 0;
  left : 100%;
  }
  .main-menu ul li:hover ul {
      
   visibility: visible;transition: all 0.4s ease 0s; opacity: 1;transform: scale(1);;
  }
  .main-menu ul li a i {
  margin-left : 6px;
  }
  .menu-icon {
      display: inline-block;
      padding: 33px 0px;
      position: absolute;
      right: 0;
      top: 0;
  }
  .cart-icon, .site-search {
      display: inline-block;
      padding: 0px 11px;
  }
  .cart-icon a i{font-size:19px}
  .site-search i {
    color: #555555;
    cursor: pointer;
  }
  .search-forum > form {
    display: inline-flex;
    background: #07358B;
    padding: 10px;
  }
  .search-forum input[type=text] {
    padding: 10px;
  }
  .search-forum input[type="submit"] {
    padding: 10px;
    background: #282828;
    color: #fff;
  }
  .site-search:hover .search-forum {visibility:visible;transition: all 0.4s ease 0s;transform:scale(1);opacity:1;}
  .search-forum {
  display: inline-block;
  left: -240px;
  opacity: 0;
  position: absolute;
  top:92px;
  transition: all 0.4s ease 0s;
  visibility: hidden;z-index:2;transform:scale(0)
  }
  /*
  5.Slider Section
  ======================*/
  .all-slide .owl-item {
    height: 550px;
  }
  .all-slide .single-slide {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  }
  .slider-text {
  left: 0;
  margin:0 auto;
  text-align: center;
  position: relative;
  right:0;
  text-align:center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 1170px;
  }
  .slider-text ul {
    margin-top: 70px;
  }
  .slider-text h1 {
      color: #fff;
      font-size: 38px;
      text-transform: uppercase;
      font-weight: 800;line-height: 70px;
  }
  .slider-text h1 span{color:#ffdc4e;}
  .slider-text p {
    color: #fff;
  }
  .slider-text li {
    display: inline-block;
  }
  .slider-text li a {
      background: transparent;
      color: #fff;
      display: inline-block;
      padding: 14px 30px;
      text-transform: uppercase;
      margin: 0px 10px;
      border: 1px solid #07358B;
      position:relative;
      z-index:5;
      transition: 0.5s;
  }
  .slider-text li a:hover{background:#07358B;color:#fff;transition: all 0.4s ease 0s;border-color:#07358B;}
  .slider-text li:last-child a{border-color:#fff;}
  .slider-text li:last-child a:hover{background:#fff;color:#292929;transition: all 0.4s ease 0s;border-color:#fff;}
  .all-slide .owl-controls {
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 99;
  transform: translateY(-50%);
  }
  .all-slide .owl-controls .owl-buttons div {
  background: none;
  opacity: 1.00;
  }
  
  .all-slide .owl-controls .owl-prev {
    left: 20px;
    position: absolute;transition: all 0.4s ease 0s;transform:scale(0);
  }
  .all-slide:hover .owl-prev{transition: all 0.4s ease 0s;transform:scale(1);}
  
  .all-slide .owl-controls .owl-next {
    right: 20px;
    position: absolute;transition: all 0.4s ease 0s;transform:scale(0);
  }
  .all-slide:hover .owl-next{transition: all 0.4s ease 0s;transform:scale(1);}
  .all-slide .owl-controls .owl-prev i,
  .all-slide .owl-controls .owl-next i {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #fff;
  border-radius: 100%;
  color: #fff;
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.5s ease-out 0s;
  width: 40px;
  }
  
  .all-slide .owl-controls .owl-next i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  .all-slide .owl-controls .owl-prev i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  
  /*
  6.Home 2 
  =================*/
  .header-right span {
      color: #ffffff;
  }
  .header-right span i {
      margin-right: 10px;
  }
  .hd-search-field {
      position: absolute;
      right: 24px;
      top: 5px;
  }
  .hd-search-field form {
      width: 190px;
      position: relative;
  }
  .hd-search-field input[type="text"] {
      background: transparent;
      padding: 3px 35px 3px 10px;
      width: 100%;
      border: 1px solid #858484;
      border-radius: 6px;
  }
  .hd-search-field button {
      background: transparent;
      color: #fff;
      position: absolute;
      padding: 4px 10px;
      right: 0;
      top: 0;
  }
  .hd-search-field button i{color:#858484;}
  .blog2-feature-text h2 a {
      color: #282828;
      font-size: 20px;
  }
  .blog2-feature-text ul {
      margin-bottom: 10px;
  }
  .blog2-feature-text ul li {
      display: inline-block;
      margin-right: 20px;
      color: #999999;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
  }
  .blog2-feature-text ul li i{margin-right:10px}
  .blog2-feature-text a.read-more {
      color: #999;
      border: 1px solid #ddd;
      padding: 14px 30px;
      text-transform: capitalize;
      border-radius: 0;
  }
  .blog2-feature-text a.read-more:hover{background:#07358B;color:#fff;border-color:#07358B;}
  .blog2-recent-inner .media-left {
      float: left;
  }
  .blog2-recent-inner {
      margin-bottom: 30px;
  }
  .blog2-recent-inner:last-child{margin-bottom:0}
  .blog2-recent-inner span i {
      color: #4cc706;
      margin-right: 10px;
  }
  .blog2-recent-inner h2{margin-bottom:10px;padding-bottom:0}
  .blog2-recent-inner h2 a {
      color: #282828;
      font-size: 16px;
      font-weight: 600;
  }
  .blog2-recent-inner span {
      color: #999999;
      font-weight: 600;
      font-size: 14px;
      text-transform: capitalize;
  }
  .blog2-recent-inner span:last-child{padding-left:15px}
  .blog2-wedget-three li i {
    display: inline-block;
    margin-right: 10px;
  }
  .blog2-recent-inner span i {
      color: #4cc706;
      margin-right: 10px;
  }
  .single-blog2 {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
  }
  .all-blog2-feature .owl-controls {
      margin-top: 20px;
      transform: translateX(-30%);
      text-align: center;
  }
  
  .all-blog2-feature.owl-theme .owl-dots .owl-dot {
  border: 2px solid #9e9e9e;
  border-radius: 100%;
  height: 16px;
  margin: 5px;
  width: 16px;display:inline-block
  }
  .all-blog2-feature.owl-theme .owl-dots .owl-dot span {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #fff;
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  left: -2px;
  margin: 3px;
  position: relative;
  top: -6px;
  transition: all 0.9s ease 0s;
  width: 10px;
  }
  .all-blog2-feature.owl-carousel.owl-theme .owl-dot.active {
    background: #fff none repeat scroll 0 0;border: 2px solid #07358B;
  }
  .all-blog2-feature.owl-carousel.owl-theme .owl-dot.active span{background: #07358B none repeat scroll 0 0;}
  .blog-sec .pagination.custom-pagination {
    text-align: left;
    width: 100%;
  }
  .pagination.custom-pagination li {
    display: inline-block;
  }
  .pagination.custom-pagination li a {
  color: #999999;
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  line-height: 22px;
  margin: 2px 5px;
  width: 36px;
  background: #f2f2f2;
  border: none;border-radius:10px;text-align:center
  }
  .pagination.custom-pagination li.active a {
    background: #07358B none repeat scroll 0 0;color:#fff;
  }
  .pagination.custom-pagination li:last-child a {
      display: inline-block;
      width: 100%;
  }
  .pagination.custom-pagination li a:hover{background: #07358B none repeat scroll 0 0;color:#fff;}
  /*
  7.Title Section
  =================*/
  .sec-title {
  text-align: center;
  margin-bottom: 50px;
  }
  .sec-title  h1 {
    font-size: 36px;
    text-transform: capitalize;
    color: #292929;
    font-weight: bold;
  }
  .sec-title h1 span {
    font-weight: normal;
  }
  .border-shape::before {
      background: #07358B none repeat scroll 0 0;
      bottom: 0;
      content: "";
      height: 3px;
      right: -4px;
      position: absolute;
      top: 1px;
      width: 20px;
  }
  .border-shape {
  color: #fff;
  display: block;
  height: 3px;
  left: 0;
  margin: 20px auto;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;
  width: 86px;
  }
  .border-shape::after {
      background: #07358B none repeat scroll 0 0;
      bottom: 0;
      content: "";
      height: 3px;
      position: absolute;
      left: 0;
      text-align: center;
      top: 1px;
      width: 65px;
  }
  .sec-title  p {
    margin-bottom: 0;
  }
  
  /*
  8.About Section
  ===================*/
  .about-sec-area {
    margin-bottom: 100px;
    display: inline-block;
    width: 100%;
  }
  .about-us-img {
      position: relative;
  }
  .about-us-overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;border:20px solid rgba(0, 0, 0, 0.1);
  }
  .about-us-overlay::before {
      position: absolute;
      top: 0;
      content: "";
      height: 100%;
      width: 100%;
      border:10px solid rgba(221, 221, 221, 0.40);
  }
  .designation {
      font-style: normal;
      font-size: 18px;
  }
  .about-text h2 {
      font-size: 40px;
      text-transform: capitalize;
      font-weight: 300;
  }
  .about-intro {
      margin-top: 10px;
      display: inline-block;
  }
  .about-intro i {
      margin-right: 4px;
      color: #999999;
  }
  .all-founder {
      padding-bottom: 10px;
      background: #fafafa;
  }
  .all-founder.owl-carousel.owl-theme .owl-item .single-founder img {
  height: 100px;
  margin: 0 auto;
  width: 100px;
  border-radius: 100%;
  position:relative;
  z-index:9;
  }
  .single-founder {
      padding:15px;
      position: relative;
      text-align: center;
      color: #999;
      font-style: italic;
      font-size: 16px;
  }
  .single-founder p{
      margin-bottom:0px;
  }
  .single-founder::before {
      position: absolute;
      width: 100%;
      height: 120px;
      content: "";
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      background-position: center center;background-color:#07358B;
      background-size: cover;
  }
  .all-founder.owl-carousel.owl-theme .owl-controls{text-align:center}
  .all-founder.owl-carousel.owl-theme .owl-dot {
    border: 2px solid #07358B;
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    margin: 0px 5px;
  }
  .all-founder.owl-carousel.owl-theme .owl-dot.active{background:#07358B}
  .person-info {
  margin-top: 30px;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  }
  .person-info > h2 {
      color: #292929;
      font-weight: bold;
      margin-bottom: 10px;
      text-transform: capitalize;
      font-size: 18px;
      font-style: normal;
  }
  .about-tab {
    margin-bottom: 40px;
  }
  .about-tab li {
  display: inline-block;
  margin-right: 10px; transition: all 0.4s ease 0s;
  }
  .about-tab li a:hover{background: #07358B none repeat scroll 0 0; transition: all 0.4s ease 0s;color:#fff;border-color:#07358B;}
  .about-tab li.active a{border-color:#07358B;color:#07358B}
  .about-tab li.active a:hover{color:#fff}
  .about-tab li a {
      color: #3db9ef;
      font-weight: 400;
      width: 130px;
      display: inline-block;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: #999999;
      text-transform: capitalize;border:1px solid #f0f0f0
  }
  /*
  9.Service Section
  ============================*/
  .service-thumb {
    height: 220px;
    overflow: hidden;transition: all 0.4s ease 0s;
  }
  .service-content {
      background: #f9f9f9 none repeat scroll 0 0;
      padding: 15px;
  }
  .service-content p{margin-bottom:0}
  .service-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;visibility:hidden;background-color: rgba(0, 0, 0, 0.59);transition: all 0.4s ease 0s;
  }
  .service-thumb img {
    width: 100%;
  }
  .service-item .inner{
  border-radius: 2px;
  margin-bottom: 30px;
  position: relative;
  margin-bottom: 30px;display:inline-block; width: 100%;}
  .service-item .inner::before {
   position:absolute;top:0;left:0;
  content:'';
  z-index: 9;
  border-left: 60px solid transparent;
  border-top: 60px solid #0657a3;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  transform: rotate(-90deg); 
    }
  
  .service-item .inner::after {
   position:absolute;bottom:0;right:0;
  content:'';
  z-index: 9;
  border-left: 60px solid transparent;
  border-top: 60px solid #0657a3;
  content: "";
  height: 0;
  position: absolute;
  width: 0;
  transform: rotate(90deg); 
  }
  
  .rounded_img{
      border-radius: 10px;
  }
  
  .service-item .inner h2 {
      text-transform: capitalize;
      font-size: 14px;margin-top:0px
  }
  .service-item .inner h2 a{color:#282828}
  /*
  10.Service 2 Section
  ============================*/
  .service2-inner {
      text-align: center;position:relative;margin-bottom:80px
  }
  .service2-id {
      font-size: 120px;
      position: absolute;
      top: 25px;
      font-weight: 600;
      color: #000;
      opacity: 0.1;transform: translateX(-90%);
  }
  .service2-icon {
      position: relative;
      display: inline-block;
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin: 0px auto;
      text-align: center;z-index:2
  }
  .service2-icon::before {
      background: #07358B none repeat scroll 0 0;
      content: "";
      height: 80px;
      position: absolute;
      transform: rotate(45deg);
      width: 80px;z-index:-1;left:0;top:0
  }
  .service2-content {
      margin-top: 40px;
  }
  .service2-content h2 a {
      font-size: 20px;
      text-transform: capitalize;
  }
  /*
  11.Count Up Section 
  ============================*/
  .count-up-sec {
  background: #07358B none repeat scroll 0 0;
  padding: 90px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  }
  .count-up-sec-overlay{background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;}
  .counting_sl {
  text-align: center;
  }
  .countup-icon {
    display: inline-block;
    height: 70px;
    margin-bottom: 16px;
    text-align: center;
    width: 70px;
  }
  .counting_sl h2 {
  color: #fff;
  font-size: 40px;
  text-transform: uppercase;
  }
  .counting_sl h4 {
      color: #fff;
      font-size: 22px;
      margin-bottom: 0;
      text-transform: capitalize;font-weight:300
  }
  /*
  12.Discount Section 
  ======================*/
  .message-intro-sec{background: #07358B url("http://themeearth.com/wp-admin/setup-config.php") no-repeat scroll center center / cover ;
  padding: 50px 0;
  position: relative;}
  .message-intro-link {
    text-align: right;
  }
  .message-intro-link a {
    background: #07358B none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    margin-top: 10px;
    padding: 14px 30px;
    text-transform: uppercase;font-weight:bold
  }
  .message-intro-link a:hover{background: #ffbc00 none repeat scroll 0 0;color: #fff;}
  .message-intro  h2 {
    color: #fff;
    font-size: 24px;
  }
  .message-intro  span {
    color: #07358B;
    font-weight: 600;
  }
  
  /*
  13.Service Details Page 
  ============================*/
  .service-details-text {
      display: inline-block;
      width: 100%;
      margin-top: 40px;
  }
  .service-details-img {
      margin-bottom: 40px;
  }
  
  /*
  14.Discount Section 
  ============================*/
  .discount-intro {
  background: #07358B none repeat scroll 0 0;
  padding: 20px 0px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;z-index:2;display: inline-block;
  width: 100%;
  }
  .discount-intro-overlay{background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;z-index:-1}
  .discount-text h2 {
      color: #fff;
      font-size: 36px;
      font-weight: 600;margin-bottom:5px
  }
  .discount-text h2 span{color:#07358B;}
  .discount-text {
      display: inline-block;
      float: left;margin-left:60px
  }
  .discount-text span.discount-subdesc{color:#fff;font-size:20px}
  .discount-button {
      display: inline-block;
      float: right;
  }
  .discount-button{
  text-align: center;
  position: absolute;
  right:60px;
  text-align:center;
  top: 50%;
  transform: translateY(-50%);
  }
  .discount-button a {
      background: #fff;
      color: #07358B;
      padding: 14px 20px;
      text-transform: capitalize;
      border-radius: 8px;
  }
  .discount-button a:hover{background: #07358B;color:#fff;}
  /*
  15.Why Choose Us Section
  ============================*/
  .why-choose {
      background-color: #fff;
      background-image: url("../img/why_bg.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
  }
  .why-choose .inner {
  margin-bottom: 30px;
  text-align: center;
  }
  .why-choose .sec-title {
    text-align: left;
  }
  .why-choose .border-shape{margin:0px}
  .inner-number h1 {
      font-size: 22px;
      color: #0657a3;
      font-weight: 400;
      margin-bottom: 20px;
  }
  .why-choose-inner {
      display: inline-block;
      float: right;
      padding: 50px 15px 30px 50px;
      position: relative;
      text-align: left;
      width: 55%;
      z-index: 9;
  }
  .why-choose-inner::before {
      background-color:#f9f9f9;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 5000px;
      z-index: -9;
  }
  .why-choose-us-tab .sec-title {
      text-align: left;
  }
  .why-choose-us-tab .border-shape{margin:0}
  
  /*
  16.Gallery Section
  ============================*/
  .simplefilter {
      position: relative;
      text-align: center;
      margin-bottom: 40px;
      display: inline-block;
      width: 100%;
  }
  .shorttitle {
    display: none;
  }
  .simplefilter li {
  color: #bebebe;
  cursor: pointer;
  display: inline-block;
  margin: 0 6px;
  padding: 10px 30px;font-size:16px;
  text-transform: capitalize;background:#f9f9f9
  }
  .simplefilter li:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;
  }
  .simplefilter li.active {
      background: #07358B;color:#fff;position:relative
  }
  .simplefilter li.active:hover{transition: all 0.5s ease-out 0s;background:#389503;color:#fff;}
  .gallery-item {
      position: relative;overflow:hidden
  }
  .gallery-item img{width:100%}
  .gallery-item:hover .gallery-overlay {transition: all 0.4s ease 0s;top:0}
  .gallery-item:hover .gallery-overlay-text {transition: all 0.4s ease 0s;transform:scale(1);visibility:visible;}
  .gallery-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 100%;
      transition: all 0.4s ease 0s;
  }
  .gallery-overlay-text {
      position: absolute;
      bottom: 3px;
      width: 80%;
      padding: 5px;
      text-align: center;transform:scale(0);visibility:hidden;
  }
  .gallery-overlay-text h2 {
      color: #ffffff;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 20px;
      margin-top: 10px;
  }
  .gallery-button a i {
      color: #fff;
      font-size: 24px;
      width: 60px;
      height: 60px;
      border: 1px solid #fff;
      border-radius: 100%;
      line-height: 60px;
  }
  .project-gallery .inner {
      width: 25%;
      display: inline-block;
  }
  .gallery-sec .gallery-item {
      margin-bottom:2px;
  }
  .gallery-sec .simplefilter li.active{color:#4cc704;background:transparent;}
  .col-xs-6.col-sm-6.col-md-4.filtr-item {
      padding: 5px;
  }
  /*
  17.Project Details Section
  ============================*/
  .project-details-text h1 {
      font-size: 36px;
      color: #282828;
      font-weight: 400;
      margin-bottom: 40px;
  }
  .project-details-desc {
      margin-top: 40px;
  }
  /*
  18.FAQ Section
  ============================*/
  .faq-sec .sec-title {
  text-align:left;
  }
  .faq-sec .sec-title .border-shape{margin:0}
  .faq-sec .panel {
    background: transparent;
  }
  .faq-sec .panel-content {
      padding-bottom: 20px;
      color: #999;
      padding-left: 40px;
  }
  
  .faq-img {
  display: inline-block;
  width: 100%;
  }
  .faq-img img {
    width: 100%;
  }
  .faq-sec .panel-heading {
  margin-bottom:20px;
  padding: 0;
  }
  .faq-sec .panel {
  
   border: none;
      border-radius: 0;
      -webkit-box-shadow: 0;
       box-shadow: 0 1px 1px rgba(0,0,0,.0);
  }
  .faq-sec .panel-heading a {
  color: #282828;
  display: inline-block;
  font-size: 18px;
  width: 100%;
  font-weight:600
  }
  .faq-sec .panel-title a:before {
      float:left !important;
      font-family: FontAwesome;
      content:"\f192";
      padding-right: 20px;color:#4cc704
  }
  .faq-sec .panel-title a.collapsed:before {
      float: left !important;
      content:"\f10c";color:#999999;padding-right: 20px
  }
  .faq-sec.faq-style2 .panel-title a:before {
      float:left !important;
      font-family: FontAwesome;
      content:"\f146";
      padding-right: 20px;color:#4cc704 
  }
  .faq-sec.faq-style2 .panel-title a.collapsed:before {
      float: left !important;
      content:"\f0fe";color:#4cc704;padding-right: 20px
  }
  .faq-sec .panel-title > a:hover, 
  .faq-sec .panel-title > a:active, 
  .faq-sec .panel-title > a:focus  {
      text-decoration:none;color:#4cc704;
  }
  
  .faq-style2-inner h2{
      color: #555555;
      display: inline-block;
      font-size: 16px;
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
  }
  h2.faq-title{
      font-size: 24px;
      text-transform: capitalize;
      font-weight: bold;
      margin-bottom: 50px;
  }
  .faq-style1-icon i {
      color: #07358B;
      margin-left: 1px;
  }
  .faq-style1 h2 {
      color: #333333;
      text-transform: capitalize;
      font-size: 20px;
      margin-bottom: 15px;
  }
  
  /*
  19.Blog Section
  ==================*/
  
  .single-post {
    display: inline-block;
    margin-bottom: 30px;
  }
  .single-blog.inner .single-post {
    margin-bottom: 10px;
  }
  .blog-detail-page .single-post-text {
    padding: 0;
    margin-top: 25px;
  }
  
  .single-blog.inner .single-post img {
    margin-bottom: 25px;
  }
  .single-blog.inner {
    margin-bottom: 40px;
  }
  
  /*latest blog*/
  .single-post-thumb {
      position: relative;
      overflow: hidden;
  }
  .single-post:hover .single-post-thumb img{transform:scale(1.2);transition: all 0.4s ease 0s;}
  .project-gallery .inner:hover .gallery-item img{transform:scale(1.2);transition: all 0.4s ease 0s;}
  .service-item .inner:hover .service-thumb img{transform:scale(1.2);transition: all 0.4s ease 0s;}
  .single-post-thumb img,
  .gallery-item img,
  .service-thumb img{transform:scale(1);transition: all 0.4s ease 0s;}
  .service-item .inner:hover h2 a,
  .service2-inner:hover h2 a{color:#07358B}
  .single-post:hover .single-post-thumb-overlay h2{
  transition: all 0.4s ease 0s;
  transform:translateY(-50%);
  top:50%	
  }
  .single-post-thumb-overlay {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
  }
  
  .single-post-thumb-overlay h2 {
      position: absolute;
      bottom: 0;
      width: 65px;
      height: 63px;
      background: #242424;
      margin-bottom: 0;
      color: #fff;
      text-align: center;
      font-size: 24px;
      text-transform: capitalize;
      padding-top: 8px;
      transition: all 0.4s ease 0s;
  }
  .single-post-thumb-overlay h2 span {
      color: #07358B;
      width: 100%;
      display: inline-block;
  }
  .post-info {
      display: inline-block;
      width: 100%;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding: 10px 0;
  }
  .post-author-thumb {
      width: 40px;
      height: 40px;
      float: left;
      border-radius: 100%;
      margin-right: 10px;
  }
  .post-author-thumb img {
      border-radius: 100%;
  }
  .author-info h3 {
      color: #282828;
      text-transform: capitalize;
      font-size: 16px;
      margin-bottom: 0;
  }
  .author-info span {
      color: #999999;
      font-style: italic;
      font-size: 13px;
  }
  .author-info {
      display: inline-block;
      float: left;
  }
  .post-meta {
      display: inline-block;
       float: right;
  }
  .post-meta li {
    display: inline-block;
    margin-right:5px;text-transform:capitalize;padding-right:10px
  }
  .post-meta li:last-child{padding-right:0px}
  .post-meta li a{color: #999999;font-size:15px;margin-top:10px}
  .post-meta li a i{color:#999999;margin-right:5px}
  .post-tags {
    display: inline-block;
    float: right;
  }
  .blog-title  h2 {
  color: #0f0f0f;
  font-size: 24px;
  font-weight: 900;
  line-height: 30px;
  }
  .single-post-text a {
      color: #282828;
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
      display: inline-block;
      width: 100%;
  }
  .pst_info {
    float: left;
    display: inline-block;
  }
  .post-date h2 {
    background: #07358B;
    text-align: center;
    padding: 10px 5px;
    font-size: 18px;
    color: #fff;
  }
  .blog-title {
    padding-bottom: 20px;
  }
  .single-post  img {
  width: 100%;
  }
  
  .comment-list {
  border: 1px solid #fafafa;
  font-size: 18px;
  padding: 14px;
  text-align: center;
  }
  .comment-list h5 {
    margin-bottom: 0;
  }
  .single-post-text h2 {
  margin-bottom: 15px;
  margin-top: 5px;
  }
  .post-meta li:last-child {
    float: right;
  }
  .post-meta li:last-child {
    float: right;
  }
  .single-post-text li a {
  float: left;
  font-size: 15px;
  font-weight: normal;
  margin-left: 1px;
  padding-right:2px;
  text-transform: capitalize;
  }
  
  .post-date h1 {
    background: #f3af1e none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    margin-top: 13px;
    padding: 15px;
    text-align: center;
    text-transform: none;
  }
  .commentar-sec img {
      float: left;
      margin-right: 20px;
      width: 100px;
      border-radius: 100%;
      height: 100px;
  }
  .comment-author {
      margin-bottom: 10px;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: bold;
      color: #282828;
  }
  .comment-time {
      color: #9a9999;
      font-size: 14px;
      font-weight: normal;
  }
  .commentar-sec .media.mt-3 {
    margin-top: 40px;
  }
  .single-post .contact-field h2 {
      font-size: 20px;
      margin-bottom: 20px;
      margin-top: 40px;
      text-transform: capitalize;
      font-weight: bold;
      color: #292929;
  }
  .commentar-sec h2 {
  margin-top: 50px;
  font-weight: bold;text-transform:capitalize;margin-bottom:30px
  }
  .replay-button a {
      float: right;
      color: #999999;
      font-weight: 600;
  }
  .replay-button a:hover{color:#07358B;}
  .commentar-sec.author ul li {
    display: inline-block;
    margin-right: 20px;
  }
  .commentar-sec.author {
    position: relative;
  }
  .commentar-sec.author ul li a i{color:#cbc8c8}
  .commentar-sec.author ul li a i:hover{transition: all 0.4s ease 0s;color:#29ace4}
  .commentar-sec.author .media-body {
    padding-left: 100px;
  }
  .commentar-sec.author .media {
  background: #fafafa none repeat scroll 0 0;
  border-radius: 30px;
  padding: 30px 0;
  margin-left: 20px;
  }
  .author-thumb {
  border-radius: 100%;
  float: left;
  height: 100px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 70px;
  width: 100px;
  }
  .author-thumb img {
    height: 100%;
    width: 100%;
  }
  .pagination.custom-pagination {
    text-align: center;
    width: 100%;
  }
  .code-text {
      font-style: italic;
      padding: 10px 40px;
      font-size: 15px;
      position: relative;
  }
  .code-text::before {
      color: #bebaba;
      content: "ï„";
      font-family: fontawesome;
      font-size: 30px;
      left: 20px;
      position: absolute;
      top: 25px;
      transform: translateY(-50%);
      text-align: center;
      left: 0;
      font-style: normal;
  }
  
  /*
  20.Sidebar Section
  =====================*/
  .sidebar h1 {
      display: inline-block;
      font-size: 22px;
      margin: 40px 0;
      text-transform: capitalize;
      width: 100%;
      color: #282828;
      position: relative;
      padding-bottom: 10px;
      font-weight: bold;
  }
  .sidebar h1::before {
    background: #07358B none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    position: absolute;
    width: 50px;
  }
  .search-field form {
  display: inline-block;
  width: 100%;
  position: relative;
  }
  
  .search-field input[type=text] {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #e7e9e6;
  padding: 10px 35px 10px 10px;
  width: 100%;
  }
  
  .search-field button {
  color: #e0dddd;
  padding: 10px;
  position: absolute;
  right: 0;
  background: transparent;top:0
  }
  
  .blog-sec .inner:nth-child(2n+1) {
    clear: both;
  }
  .abt_dec > p {
    margin-top: 20px;
  }
  
  .sidebar ul li a{font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out 0s;}
  
  .sidebar ul li a:hover{transition: all 0.4s ease-in-out 0s;color:#07358B}
  .widget-archive li {
      display: inline-block;
      position: relative;
      width: 100%;
      background: #f2f2f2;
      padding: 10px 20px;
      color: #999;
      margin-bottom: 5px;
  }
  .widget-archive li:hover{background:#07358B;color:#fff;transition: all 0.4s ease-in-out 0s;}
  .widget-archive li a:hover{color:#fff!important;transition: all 0.4s ease-in-out 0s;}
  .widget-archive li:last-child {
    border-bottom: none;
  }
  .widget-cat li:last-child {
    border-bottom: none;
  }
  .widget-archive li a{color:#999;
  display:inline-block;
  width:100%;
  }
  .widget-cat li{position:relative}
  
  .widget-cat li {
    padding: 10px 0;
    border-bottom: 1px solid #ececec;
  }
  .widget-cat li a span{float:right;}
  .widget-tag li a:hover{transition: all 0.4s ease-in-out 0s;color:#07358B!important}
  .relative-post h3 {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: bold;
      color: #999999;
  }
  .single_r_dec li {
      display: inline-block;
      margin-right: 10px;
  }
  .single_r_dec li a{font-weight:600;text-transform:capitalize}
  .single_r_dec li a i{color:#07358B;margin-right:5px}
  .relative-post {
  display: inline-block;
  padding: 12px 0;
  width: 100%;
  }
  .relative-post-thumb {
    float: left;
    margin-right:25px;
  }
  .single_r_dec li a {
      color: #07358B;
  }
  .single_r_dec li a span{color:#999999;}
  .single_r_dec  a:hover{ transition: all 0.4s ease-in-out 0s;color:#07358B}
  .all_r_pst .media {
    margin-top: 0;
  }
  .widget-tag a {
      float: left;
      margin-right: 5px;
      margin-bottom: 5px;
      margin-right: 10px;
  }
  
  .author_dec h3 {
  margin : 0;
  color : #333333;
  font-size : 14px;
  margin-bottom : 11px;
  }
  .author_info {
  border-bottom : 1px solid #e3e3e3;
  display : inline-block;
  padding : 22px 0;
  margin-bottom : 30px;
  }
  .author_info > img {
    float: left;
    margin-right: 35px;
    height: 80px;
  }
  .social_link {
  margin : 0;
  padding : 0;
  float : right;
  display : inline-flex;
  }
  .social_link li {
  list-style : none;
  }
  .social_link li a i {
  color : #363636;
  float : left;
  padding : 6px;
  }
  .price_filter {
      margin-bottom: 10px;
      display: inline-block;
      width: 100%;
  }
  .price_filter .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    background: #f0f0f0 none repeat scroll 0 0;
    border: medium none;
    height:16px;
    margin-bottom:20px;
    margin-left: auto;
  }
  .ui-slider-horizontal .ui-slider-range {
      top: 0;
      height: 100%;
  }
  
  .price_filter .ui-slider-handle.ui-state-default.ui-corner-all {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #07358B;
    border-radius: 20px;
    cursor: pointer;
    height: 26px;
    width: 40px;
  }
  .price-amount > input[type="text"] {
      font-weight: 600;
      height: 50px;
      letter-spacing: 3px;
      text-align: center;
      width: 214px;
      border-right: 0;
      color: #9e9e9e;
      float: right;
      background: #f4f4f4;
  }
  .price-amount > input[type="submit"] {
      background: #07358B;
      border: 0px solid #07358B;
      float: left;
      font-weight: 600;
      height: 50px;
      transition: all 400ms ease-out 0s;
      width: 104px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;
      font-family: 'Raleway', sans-serif;
      margin-right: 10px;
  }
  .price-amount > input[type=submit]:hover {
  background: transparent;
   color:#07358B;
   border: 1px solid #07358B;
  }
  .price_filter .ui-slider-range {
    background: #c8c8c8 none repeat scroll 0 0;
  }
  .discount-widget {
      margin-top: 40px;position:relative
  }
  .discount-widget img {
      width: 100%;
  }
  .discount-widget-overlay {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;background-color: rgba(0, 0, 0, 0.70);
  }
  .discount-widget-wrapper span {
      color: #07358B;
      font-size: 45px;
      font-weight: bold;
  }
  .discount-widget-wrapper h2 {
      color: #ffffff;
      font-size: 36px;
      font-weight: normal;
      margin-bottom: 10px;
  }
  .discount-widget-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  }
  .discount-widget-wrapper a {
      color: #fff;
      font-size: 15px;
      font-weight: 600;
      padding: 13px 40px;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.4);
      margin-top: 10px;
  }
  /*
  21.Testimonial Section
  =========================*/
  .testimonial-sec{background:#fbfbfb}
  .testimonial-text span {
      color: #07358B;
      display: inline-block;
      margin-bottom: 14px;
      font-size: 16px;
      font-weight: 400;
  }
  .testimonial-text > h1 {
  color: #282828;
  font-size: 36px;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 30px;;
  }
  .testimonial-text a {
    background: #07358B none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 14px 55px;
    text-transform: capitalize;
  }
  .no-padding {
    padding: 0;
  }
  .all-testimonial.owl-carousel .owl-item img {
  border-radius: 100%;
  }
  .all-testimonial .owl-controls {
  bottom: -30px;
  position: absolute;
  text-align: center;
  width: 100%;
  }
  .all-testimonial .owl-controls .owl-buttons div {
    background: none;
  opacity:1
  }
  .client-info {
      display: inline-block;
  }
  .client-thumb {
      border-radius: 100%;
      height: 60px;
      margin-right: 20px;
      width: 60px;
      float: left;
  }
  .sngl-client {
  border: 1px solid #fafafa;
  margin-bottom: 30px;
  text-align: center;
  margin: 5px;
  }
  .single-testimonial {
  display: inline-block;
  margin-bottom: 40px;
  padding: 0 10px;
  width: 100%;
  }
  .clinet-inner {
      margin-top: 20px;
  }
  .client-info h2 {
      color: #292929;
      font-size: 18px;
      margin-bottom: 5px;
      text-transform: capitalize;
      font-weight: 600;
  }
  .client-info span {
      color: #999999;
      font-size: 14px;
      display: inline-block;
      text-transform: uppercase;
  }
  .client-comment span::before {
    height: 1px;
    background: #07358B;
    width: 40px;
    content: "";
    position: absolute;
    bottom: 0;
  }
  
  .client-comment  h3 {
  color: #07358B;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: normal;
  font-style: italic;
  }
  .client-comment {
      background: #fff none repeat scroll 0 0;
      border: 1px solid #eeeeee;
      margin-top: 30px;
      padding: 20px 19px 20px 20px;
      position: relative;
      font-style: italic;
      border-radius: 10px;
  }
  .client-comment::after {
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 16px solid #eeeeee;
      content: "";
      height: 0;
      left: 12px;
      position: absolute;
      width: 0;
      transform: rotate(0);
      bottom: -16px;
  }
  .client-comment::before {
  color: #999999;
  content: "ï„";
  font-family: fontawesome;
  font-size: 71px;
  left: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  opacity: 0.10;font-style:normal
  }
  .client-reviews i {
    color: #f2b827;
    font-size: 13px;
  }
  .client-comment > ul {
    padding-bottom: 15px;
  }
  .client-comment li {
    display: inline-block;
  }
  .client-comment li a{
  border: 2px solid #07358B;
  border-radius: 100%;
  color: #07358B;
  display: inline-block;
  height: 30px;
  line-height: 26px;
  width: 30px;
  margin: 0px 5px;
  }
  .client-comment li a:hover{background:#07358B;transition: all 0.4s ease-in-out 0s;color:#fff}
  .client-sec .owl-controls {
    display: none;
  }
  
  .all-testimonial.owl-theme .owl-dots .owl-dot {
  border: 2px solid #9e9e9e;
  border-radius: 100%;
  height: 16px;
  margin: 5px;
  width: 16px;display:inline-block
  }
  .all-testimonial.owl-theme .owl-dots .owl-dot span {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #fff;
  border-radius: 100%;
  display: inline-block;
  height: 10px;
  left: -2px;
  margin: 3px;
  position: relative;
  top: -6px;
  transition: all 0.9s ease 0s;
  width: 10px;
  }
  .all-testimonial.owl-carousel.owl-theme .owl-dot.active {
    background: #fff none repeat scroll 0 0;border: 2px solid #07358B;
  }
  .all-testimonial.owl-carousel.owl-theme .owl-dot.active span{background: #07358B none repeat scroll 0 0;}
  
  /*
  22.Page Heading Section
  ==========================*/
  .pagehding-sec {
      background-position: center center;
      background-repeat: no-repeat;
      padding-bottom: 120px;
      padding-top: 120px;
      position: relative;
      background-size: cover;
  }
  .page-heading h1 {
      color: #fff;
      font-size: 40px;
      text-align: center;
      text-transform: capitalize;
      font-weight: bold;
  }
  .breadcrumb-list {
      text-align: center;
  }
  .breadcrumb-list li {display: inline-block;
  position: relative;
  padding: 0px 14px;}
  .breadcrumb-list li a {
    color: #ffffff;font-weight:600;text-transform:capitalize
  }
  .breadcrumb-list li:last-child a {
    color: #ffdc4e;
  }
  .breadcrumb-list li::before {
    content: "\f192";
  position: absolute;
  right: -8px;
  top: 0px;
  color: #fff;
  font-family: fontawesome;
  }
  .breadcrumb-list li:last-child::before{display:none}
  .breadcrumb-list li:last-child::before {
    display: none;
  }
  
  /*
  23.Team section
  =================*/
  .team-sec .sec-title {
      text-align: left;
  }
  .team-sec .sec-title .border-shape {
      margin: 0;
  }
  .team-member {
  margin-bottom: 30px;
  overflow: hidden;
  padding-bottom: 6px;
  text-align: center;
  }
  .team-member-thumb {
      position: relative;
      overflow: hidden;
      border-radius:0 60px 60px 60px;
  }
  .team-member-thumb img{
      width:100%;
  }
  .team-member:hover .team-overlay{visibility:visible; transition: all 0.4s ease-in-out 0s;opacity:1;top:0}
  .team-overlay {
  position: absolute;
  top: 100%;
  transition: all 0.4s ease-in-out 0s;
  visibility: hidden;
  width: 100%;height:100%;
  background-color: rgba(76, 199, 0, 0.82);color:#fff;
  }
  .team-overlay-social-link {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .team-overlay-social-link ul {
  padding-bottom:10px
  }
  
  .team-overlay-social-link li{
        transition: 0.6s;
        -webkit-transition: 0.3s;
        -khtml-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
        -o-transition: 0.3s;
          display: inline-block;
          padding: 0 10px;	  
  }
  .team-member:hover .team-overlay-social-link li{transform: translateY(0px);}
  .team-overlay-social-link li:nth-child(1) {
  transform: translateY(20px);
  transition-delay: .1s; }
  .team-overlay-social-link li:nth-child(2) {
  transform: translateY(40px);
  transition-delay: .2s; }
  .team-overlay-social-link li:nth-child(3) {
  transform: translateY(60px);
  transition-delay: .3s; }  
  .team-overlay-social-link li:nth-child(4) {
  transform: translateY(80px);
  transition-delay: .4s; }
          
  .team-member> img{width: 100%;}
  .team-member h2 {
      font-size: 20px;
      margin-top: 20px;
      text-transform: capitalize;
      margin-bottom: 5px;
  }
  .team-member h3 {
      color: #999999;
      font-size: 15px;
      text-transform: capitalize;
      font-weight: 400;
      margin-bottom: 0;
  }
  
  .team-overlay-social-link li i{color:#fff}
  
  /*
  24.Team 2 section
  =================*/
  .team2-sec .sec-title{text-align:left;}
  .team2-sec .border-shape{margin:0}
  .team2-member-thumb {
      position: relative;overflow:hidden
  }
  .team2-member {
      margin: 20px;
  }
  .team2-member:hover .team2-overlay{opacity:1;visibility:visible;transition: all 0.4s ease 0s;left:0%;}
  .team2-member:hover .team2-overlay-text{opacity:1;transition: all 0.4s ease 0s;left:0;}
  .team2-overlay {
      position: absolute;
      width: 100%;
      top: 0;
      left:-100%;
      height: 100%;background:#07358B;opacity:0;visibility:hidden;transition: all 0.4s ease 0s;
  }
  .team2-social-link li{display:inline-block}
  .team2-social-link li a {
      color: #fff;
      width: 40px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      border: 1px solid #b3e793;
      border-radius: 100%;
      text-align: center;
      margin: 5px 0px;
  }
  .team2-overlay h2 {
      color: #ffffff;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 20px;
      margin-top: 30px;margin-bottom:10px
  }
  .team2-overlay h3 {
      color: #ffffff;
      font-size: 15px;
      text-transform: capitalize;
      font-style: italic;
      margin-bottom: 0;
  }
  .team2-overlay-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      left: -100%;
      right: 0;
      opacity:0;
      transition: all 0.4s ease 0s;
  }
  .team2-sec-page .team2-member {
      margin: 0 0 30px 0px;
  }
  .all-team .owl-controls {
      text-align: center;
      margin-top: 20px;
  }
  .all-team .owl-controls .owl-buttons div {
  background: none;
  opacity: 1.00;
  }
  .all-team .owl-controls .owl-prev {
      display: inline-block;margin-right:5px
  }
  .all-team .owl-controls .owl-next {
      display: inline-block;margin-left:5px
  }
  .all-team .owl-controls .owl-prev i,
  .all-team .owl-controls .owl-next i {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ddd;
  border-radius: 100%;
  color: #4cc703;
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.5s ease-out 0s;
  width: 40px;
  }
  
  .all-team .owl-controls .owl-next i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  .all-team .owl-controls .owl-prev i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  /*
  25.About Page
  ================*/
  .about-sec .sec-title {
      text-align: left;
  }
  .about-sec .border-shape{margin:0}
  .progress.skill-progress {
  background: #f0f0f0 none repeat scroll 0 0;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 0;
  margin-top: 0;
  height: 5px;
  }
  .skill-inner {
      margin-bottom: 30px;
  }
  .skill-inner h3 {
      color: #282828;
      text-transform: capitalize;
      margin-bottom: 15px;
  }
  .skill-bar {
    background: #07358B none repeat scroll 0 0;
    border-radius:0;
    box-shadow: none;
  
  }
  .skill-progress-bar {
      border: 1px solid #d0d0d0;
      margin-right: 70px;
      padding: 10px;position:relative;
  }
  .progres_count {
      right: -70px;
      top: 0px;
      color: #fff;
      font-weight: 600;
      position: absolute;
      color: #999999;
      font-size: 20px;
  }
  /*
  26.Contact Page
  ============================*/
  .contact-info-icon {
      float: left;
      width: 75px;
      height: 75px;
      text-align: center;
      line-height: 75px;
      margin-right: 10px;
  }
  
  .contact-page-sec .contact-field h2 {
      color: #282828;
      text-transform: capitalize;
      font-size: 22px;
  }
  
  .contact-field .col-md-6.col-sm-6.col-xs-12 {
    padding-left: 0;
  }  
  .contact-field.contact-form input {
    margin-bottom: 5px;
  }  
  .contact-field.contact-form textarea {
    height: 110px;
  }
  .contact-field.contact-form input[type="submit"] {
    background: #07358B;
    width: 150px;
    border-color: #07358B;
  }
  .contact-info-text {
      padding-left: 100px;
      margin-bottom: 40px;
  }
  .contact-info-text h2 {
      color: #282828;
      font-size: 20px;
      text-transform: capitalize;
  }
  .contact-info-text span {
      color: #999999;
      font-size: 16px;
      display: inline-block;
      width: 100%;
  }
  
  .contact-field input{
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ddd;
    margin-top: 20px;
    padding: 10px;
    width: 100%;color:#999999;
  }
  .contact-field select {
      width: 100%;
      border: 1px solid #ddd;
      padding: 13px 20px;
      margin-bottom: 20px;
      text-transform: capitalize;
      color: #999999;margin-top: 20px;
  }
  .contact-field .select-arrow::before {
      top: 30px;
      color: #999999;
  }
  .contact-field .message-input {
  display: inline-block;
  width: 100%;
  padding-left: 0;
  }
  .single-input-field textarea {
      border: 1px solid #ddd;
      width: 100%;
      height: 120px;
      padding: 10px;
      margin-top: 10px;
  }
  .single-input-fieldsbtn input[type="submit"] {
      background: #07358B none repeat scroll 0 0;
      border: 1px solid #07358B;
      color: #fff;
      display: inline-block;
      font-weight: 600;
      padding: 14px 30px;
      text-transform: capitalize;
      width: 170px;
      margin-top: 30px;
      font-size: 15px;
  }
  
  .single-input-fieldsbtn input[type="submit"]:hover{background:#07358B;transition: all 0.4s ease-in-out 0s;border-color:#07358B}
  .contact-field {
    display: inline-block;width:100%
  }
  .single-input-field  h4 {
    color: #464646;
    text-transform: capitalize;
    font-size: 14px;
  }
  .contact-page-form {
      display: inline-block;
      width: 100%;
      margin-top: 30px;
  }
  
  .map-sec iframe{width:100%;height:550px}
  .social-profile li {
      display: inline-block;
  }
  .social-profile li a {
      color: #b1b1b1;
      font-size: 30px;
      padding: 1px;
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 10px;
      text-align: center;
      border: 1px solid #fff;
      margin: 10px;
      line-height: 40px;transition: all 0.4s ease 0s;
  }
  .social-profile li a:hover{background:#ffdc4e;color:#fff;transition: all 0.4s ease 0s;}
  /*
  27.Shop Page
  ==========================*/
  .product-inner {
      margin-bottom: 50px;
  }
  .product-thumb {
      position: relative;
      text-align: center;
      margin-bottom: 30px;
      background:#07358B;
      overflow:hidden;
  }
  .product-thumb img{	transition: all 0.35s ease;}
  .product-inner:hover .product-thumb-overlay:before {top:0;visibility:visible;transition: all 0.35s ease;}
  .product-inner:hover .product-thumb img {transform:scale(0.6);transition: all 0.35s ease;}
  .product-inner:hover .product-thumb-overlay{transition: all 0.35s ease;transform:scale(1);opacity:1}
  .product-thumb-overlay {
  position: absolute;
  top: 0%;
  height:100%;
  width:100%;
      transition: all 0.35s ease;
      transform:scale(0);
      opacity:0
  }
  .product-thumb-overlay:before{
  position: absolute;
  top: 100%;
  background-color: rgba(76, 199, 0, 0.9);
  content:"";
  left:0;
  width:100%;
  height:100%;
  visibility:hidden;
  transition: all 0.35s ease;
  
  }
  .product-thumb-overlay a {
      position: absolute;
      top: 50%;
      color: #ffffff;
      font-size: 22px;
      font-weight: 600;
      text-transform: uppercase;
      transform:translateY(-50%);
      left:0;right:0;
      text-align:center;
  }
  .product-price {
      font-size: 36px;
      font-weight: bold;
      color: #bbbaba;
  }
  .regular-price {
      font-size: 18px;
      font-weight: 600;
      color: #999999;
      margin-left: 10px;
  }
  .product-text h2 {
      margin-top: 10px;
  }
  .product-text h2 a {
      color: #282828;
      text-transform: capitalize;
      font-size: 22px;
      font-weight: 600;
  }
  .product-meta {
      display: inline-block;
      width: 100%;
  }
  .product-meta span{display:inline-block}
  .product-meta span:last-child{float:right;padding:9px 0px}
  .product-meta .add-to-cart a{
  color: #999999;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid #f0f0f0;
  padding: 10px 20px;position:relative
  }
  .product-inner:hover .product-meta .add-to-cart a::before {
    background: #07358B none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .product-inner:hover .product-meta .add-to-cart a{background:#07358B;color:#fff;border-color:#07358B;}
  .add-to-rating a i {
      font-size: 20px;
      margin-left: 5px;
  }
  .add-to-rating a i.fa-star {
      color: #f2b827;
  }
  .add-to-rating a i.fa-star-o{color:#c8c8c8}
  .product-batch {
      position: absolute;
      width: 60px;
      height: 60px;
      display: inline-block;
      right:20px;
      top: 20px;
      background: #07358B;
      border-radius: 100%;
  }
  .product-batch span {
      transform: rotate(-49deg);
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      color: #fff;
      line-height: 60px;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
  }
  /*
  28.Product Details Page
  ==========================*/
  .all-product-thumb{height:350px;position:relative;background:#f0f0f0;margin-bottom:25px}
  .all-product-thumb.owl-carousel .owl-item img{width:auto;height:auto;margin: 0px auto;}
  .all-product-thumb.owl-carousel .owl-stage-outer{position: relative;
      top: 50%;
      transform: translateY(-50%);left: 0;
      right: 0;
      text-align: center;}
  .all-product-thumb .owl-controls {
  position: absolute;
  top: 45%;
  width: 100%;
  z-index: 99;
  transform: translateY(-50%);
  }
  .all-product-thumb .owl-controls .owl-buttons div {
  background: none;
  opacity: 1.00;
  }
  
  .all-product-thumb .owl-controls .owl-prev {
    left: 15px;
    position: absolute;transition: all 0.4s ease 0s;
  }
  
  .all-product-thumb .owl-controls .owl-next {
    right:15px;
    position: absolute;transition: all 0.4s ease 0s;
  }
  .all-product-thumb .owl-controls .owl-prev i,
  .all-product-thumb .owl-controls .owl-next i {
  background: #fff;
  border: 1px solid #d0d0d0;
  color: #999999;
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.5s ease-out 0s;
  width: 40px;
  }
  
  .all-product-thumb .owl-controls .owl-next i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  .all-product-thumb .owl-controls .owl-prev i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  .product-details-inner .product-price{color:#777;font-size:40px;font-weight:600}
  .product-details-inner .add-to-rating{float:right;}
  .product-cat {
      color: #777;
      text-transform: capitalize;
  }
  .product-details-inner .add-to-cart a{background:#07358B;color:#fff;border-bottom:#07358B}
  .product-details-inner .product-text {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 30px;
  }
  .product-details-inner .product-meta {
      margin-top: 30px;
      margin-bottom: 20px;
  }
  .product-tab{margin-bottom:30px}
  .product-tab li {
      display: inline-block;
  }
  .product-tab li a {
      width: 170px;
      height: 50px;
      display: inline-block;
      text-align: center;
      line-height: 50px;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      margin-right: 5px;border: 1px solid #f0f0f0;color:#999999
  }
  .product-tab li.active a{background:#07358B;border-color:#07358B;color:#fff}
  .product-tab li a:hover{background:#07358B;border-color:#07358B;color:#fff}
  .product-review-form h2, .product-desc h2 {
      text-transform: capitalize;
      font-weight: 600;
      color: #282828;
      font-size: 22px;
  }
  
  /*Related Product*/
  .related-product{margin-top:50px}
  .related-product h2 {
      color: #282828;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 22px;
  }
  .all-related-product.owl-carousel .owl-item img{width:auto;height:auto;margin: 0px auto;}
  .all-related-product .owl-controls {
  position: absolute;
  top: 30%;
  width: 100%;
  z-index: 99;
  transform: translateY(-50%);
  }
  .all-related-product .owl-controls .owl-buttons div {
  background: none;
  opacity: 1.00;
  }
  
  .all-related-product .owl-controls .owl-prev {
    left: 15px;
    position: absolute;transition: all 0.4s ease 0s;
  }
  
  .all-related-product .owl-controls .owl-next {
    right:15px;
    position: absolute;transition: all 0.4s ease 0s;
  }
  .all-related-product .owl-controls .owl-prev i,
  .all-related-product .owl-controls .owl-next i {
  background: #fff;
  border: 1px solid #d0d0d0;
  color: #999999;
  font-size: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.5s ease-out 0s;
  width: 40px;
  }
  
  .all-related-product .owl-controls .owl-next i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  .all-related-product .owl-controls .owl-prev i:hover {
  transition: all 0.5s ease-out 0s;background:#07358B;color:#fff;border-color:#07358B
  }
  .all-related-product .product-text h2 a{font-size:16px}
  .all-related-product .add-to-rating a i {
      font-size: 14px;
      margin-left: 0px;
  }
  
  /*
  29.Cart Page
  ================================*/
  
  .table-text thead tr th {
      background: #f4f4f4;
      color: #999999;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      font-family: rale way;
      padding: 20px 0px;border-bottom:1px solid #f0f0f0
  }
  .table-text table th {
      padding: 20px 0;
      text-align: center;
  }
  
  .table-text tbody {
      text-align: center;
  }
  .table-text tbody tr{border-bottom:1px solid #f0f0f0!important}
  .table-text tbody tr td {
  padding: 20px 0;
  vertical-align: middle;border:0;
  }
  .table-text .product-name a {
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      color: #333333;
  }
  
  .product-price .amount {
      color: #aaaaaa;
      font-size: 16px;
      font-weight: 600;
  }
  
  .product-total {
      color: #07358B;
      font-size: 16px;
      font-weight: 600;
  }
  .product-delete a {
      color: #aaaaaa;
      font-size: 20px;
      font-weight: 600;
  }
  .table-text .product-quantity input[type="number"] {
      text-align: center;
      font-weight: 600;
      color: #9e9e9e;
  }
  .update-cart {
      display: inline-block;
      width: 100%;
      margin: 40px 0;
  }
  .update-cart-left input[type="text"] {
      background: #f4f4f4 none repeat scroll 0 0;
      padding: 14px 30px;
      color: #555555;
      text-transform: capitalize;
      border-bottom: 2px solid #f4f4f4;
  }
  .update-cart-left input[type=submit]{
      background: #07358B none repeat scroll 0 0;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      padding: 14px 30px;
      text-transform: capitalize;font-size:14px
  }
  .update-cart-right {
      display: inline-block;
      text-align: right;
      width: 100%;
  }
  .update-cart-right li{display:inline-block;}
  .update-cart-right li a,
  .checkout-button a{background: #f4f4f4 none repeat scroll 0 0;
      color: #555555;
      display: inline-block;
      font-weight: bold;
      padding: 14px 30px;
      text-transform: capitalize;font-size:14px}
  .update-cart-right li:last-child a{background: #07358B none repeat scroll 0 0;
      text-transform:capitalize;color:#fff;}	
  .calculate-shipping {
      padding-right: 30px;
  }
  .cart-total-area {
      display: inline-block;
      width: 100%;
  }	
  .cart-total-area h2 {
      text-transform: capitalize;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 30px;
  }
  .calculate-shipping input,
   .calculate-shipping select{
      width: 100%;
      border: 1px solid #e6e6e6;
      padding: 10px 20px;
      margin-bottom: 20px;
      text-transform: capitalize;
      color: #9e9e9e;
  }
  .calculate-shipping input[type="submit"] {
      background: #07358B none repeat scroll 0 0;
      border: none;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      padding: 14px 0px;
      text-transform: capitalize;
      font-size: 14px;width:140px
  }
  .added-total-cart-table thead tr{border-top: 1px solid #e6e6e6;}
  .added-total-cart-table thead tr th {
      border: 1px solid #e6e6e6;
      font-size: 14px;
      text-transform: capitalize;
      color: #292929;
      font-family:Raleway;font-weight:600;text-align:center;padding:15px 0;
  }
  .added-total-cart-table tbody tr td {
      border: 1px solid #e6e6e6;
      font-size: 14px;
      text-transform: capitalize;
      color: #9e9e9e;text-align:center;padding:15px 0;
  }
  .checkout-button a {
      text-align: right;
      display: inline-block;
      background: #07358B;
      color: #fff;
      text-transform: capitalize;
  }
  .checkout-button a:hover,
  .calculate-shipping input[type="submit"]:hover{background: #282828 none repeat scroll 0 0;transition: all 0.4s ease 0s;}
  .checkout-button {
      text-align: right;
      margin-top: 40px;
  }
  
  /*
  30.Check Out Page
  ================================*/
  
  .billing-form h2 {
      font-size: 20px;
      color: #292929;
      text-transform: capitalize;
  }
  
  .billing-form input[type="text"], .billing-form  select, .billing-form input[type="email"],
  .billing-form input[type="password"] {
      border: 1px solid #e6e6e6;
      margin-bottom: 20px;
      padding: 10px;
      width: 100%;margin-left: -15px;
  }
  .select-arrow select{-moz-appearance: none;
  -webkit-appearance: none;cursor:pointer}
  
  .select-arrow{position:relative}
  .select-arrow:before {
  color: #323B49;
  content: "\f0d7";
  font-family: fontawesome;
  position: absolute;
  right: 30px;
  top:10px;cursor:pointer
  }
  .checkout-inner{
      display:inline-block;
      width:100%;
  }
  .order-note h2 {
      color: #333333;
      text-transform: uppercase;
      font-size: 20px;
      margin-top: 30px;
  }
  .order-note input[type="text"] {
      border: 1px solid #e6e6e6;
      margin-bottom: 20px;
      padding: 10px;
      width: 100%;height:190px
  }
  
  .one-column-single {
      display: inline-block;
      width: 100%;
  }
  .payment-method h2,
  .added-total-cart h2 {
      color: #292929;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 20px;
  }
  .payment-method {
      display: inline-block;
      width: 100%;
      padding-left: 50px;
  }
  .payment-method label.radio-inline {
      color: #033333;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 600;
      display: inline-block;
      text-align: left;
      padding-left: 30px;
      margin-bottom: 20px;
      margin-left: 0;
  }
  .payment-method label.radio-inline span {
      font-size: 16px;
      font-weight: normal;
      display: inline-block;
      text-align: left;
      color: #777;margin-top:15px
  }
  .payment-method input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }
  .control-indicator {
      position: absolute;
      top: 2px;
      left: 0;
      height: 20px;
      width: 20px;
      background: #fff;
      border: 1px solid #07358B;
  }
  .radio-inline input:checked ~ .control-indicator::after {
      display: block;
  }
  .control-indicator::after {
      content: '';
      position: absolute;
      display: none;
  }
  .radio-inline input:checked ~ .control-indicator {
      background: #07358B;
  }
  .control-radio .control-indicator {
      border-radius: 50%;
  }
  /*
  31.Account Page Section 
  ==========================*/
  .register-form span, .login-form.billing-form span {
      color: #999999;
      margin-bottom: 20px;
      display: inline-block;
      width: 100%;
  }
  .register-form.billing-form .radio-inline {
      display: inline-block;
      width: 100%;
      margin-bottom: 40px;
  }
  .register-form.billing-form input[type="submit"], .login-form.billing-form input[type="submit"] {
      background: #07358B none repeat scroll 0 0;
      color: #fff;
      display: inline-block;
      font-weight: bold;
      padding: 10px 40px;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 40px;
  }
  
  /*
  32.Patner Section 
  ======================*/
  .all-patner-sec {
    padding: 100px 0;
  }
  .all-patner.owl-carousel.owl-theme .owl-item {
  padding: 0 40px;
  }
  .single-patner {
      height: 86px;
      padding: 6px;
  }
  .all-patner.owl-carousel.owl-theme img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  }
  
  /*
  33.Message Section 
  ======================*/
  .message-intro-sec{background: #07358B url("http://themeearth.com/wp-admin/setup-config.php") no-repeat scroll center center / cover ;
  padding: 50px 0;
  position: relative;}
  .message-intro-link {
    text-align: right;
  }
  .message-intro-link a {
    background: #07358B none repeat scroll 0 0;
    color: #fff;
    display: inline-block;
    margin-top: 10px;
    padding: 14px 30px;
    text-transform: uppercase;font-weight:bold
  }
  .message-intro-link a:hover{background: #ffbc00 none repeat scroll 0 0;color: #fff;}
  .message-intro  h2 {
    color: #fff;
    font-size: 24px;
  }
  .message-intro  span {
    color: #07358B;
    font-weight: 600;
  }
  /*
  34.404 Page Section 
  ======================*/
  .error-page-content {
    text-align: center;
  }
  .error-page-content  h1 {
    color: #07358B;
    font-weight: 600;
    font-size: 60px;
  }
  .error-page-content > a {
    color: #07358B;
    font-weight: 600;
    border-bottom: 1px solid #07358B;
  }
  
  /*
  35.Footer Section 
  ======================*/
  footer.footer1 {
    background: #07358B url("../img/footer_bg.png") no-repeat scroll center center / cover ;
    position: relative;
  }
  .footer-overlay{
  background-color: rgba(36, 36, 36, 0.8);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;	
  }
  .footer2 {
      background: #282828;
  }
  .footer-sec {
  padding: 80px 0;
      padding-bottom: 40px;
      border-bottom: 1px solid #69c80e;
  }
  
  .footer-sec h2 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
  text-transform: capitalize;font-size:20px
  }
  .inner-text {
      color: #b0acac;
      font-weight: 400;
  }
  .inner-icon i {
      color: #b0acac;
      font-size: 20px;
      margin-left: 1px;
      width: 25px;
      text-align: center;
  }
  .footer-sec p {
  color: #999999;
  font-size: 15px;
  }
  .footer-wedget-one > a > img {
      position: relative;
      top: -20px;
  }
  .footer-wedget-one li a:hover{background:#07358B;color:#fff;border-color:#07358B; transition: all 0.4s ease-in-out 0s;}
  .footer-wedget-one ul {
    margin-top: 15px;
  }
  .footer-recent-inner {
      margin-bottom: 0px;
  }
  .footer-recent-inner:last-child{margin-bottom:0}
  .footer-recent-inner span i {
      color: #4cc706;
      margin-right: 10px;
  }
  .footer-recent-inner h2{margin-bottom:10px;padding-bottom:0}
  .footer-recent-inner h2 a {
      color: #999999;
      font-size: 14px;
      line-height: 15px;
      font-weight: 400;
  }
  .footer-recent-inner span {
      color: #999999;
      font-weight: 600;
      font-size: 14px;
      text-transform: capitalize;
  }
  .footer-recent-inner span:last-child{padding-left:15px}
  .footer-wedget-three li i {
    display: inline-block;
    margin-right: 10px;
  }
  .footer-recent-inner span i {
      color: #4cc706;
      margin-right: 10px;
  }
  
  .bussiness-time > span {
      color: #999999;
      display: inline-block;
      font-size: 16px;
      margin-bottom: 0;
      text-transform: capitalize;
      font-weight: 600;
      width: 100%;
  }
  .bussiness-time ul{margin-top:20px}
  .bussiness-time li {
      color: #999999;
      padding-top: 10px;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
  }
  .bussiness-time li span{color:#07358B}
  .wedget-newsletter form {
  margin-bottom: 25px;
  margin-top: 25px;
  position: relative;
  }
  
  .wedget-newsletter input[type="email"] {
      background: transparent;
      border: 1px solid #4cc706;
      padding: 10px 58px 10px 10px;
      text-transform: capitalize;
      width: 100%;
      font-weight: 600;
      color: #999999;
  }
  .wedget-newsletter button {
      color: #999999;
      position: absolute;font-size:18px;
      padding: 11px 20px;
      right: 0;
      top: 0;background:transparent
  }
  .checkbox-field {
    margin-top: 40px;background:transparent
  }
  .wedget-newsletter input[type="checkbox"]:not(:checked),
  .wedget-newsletter input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  .wedget-newsletter input[type="checkbox"]:not(:checked) + label,
  .wedget-newsletter input[type="checkbox"]:checked + label {
  color: #999999;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  padding-left: 40px;
  position: relative;
  }
  
  .wedget-newsletter input[type="checkbox"]:not(:checked) + label:before,
  .wedget-newsletter input[type="checkbox"]:checked + label:before {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #999999;
  content: "";
  height: 15px;
  left: 0;
  position: absolute;
  top: -4px;
  width: 15px;
  margin-top: 10px;
  }
  
  .wedget-newsletter input[type="checkbox"]:not(:checked) + label:after,
  .wedget-newsletter input[type="checkbox"]:checked + label:after {
  color: #fff;
  content: "ï€Œ";
  font-family: fontawesome;
  font-size: 10px;
  left: 2px;
  line-height: 0.8;
  position: absolute;
  top: 9px;
  transition: all 0.2s ease 0s;
  }
  .wedget-newsletter input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  .wedget-newsletter input[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  .footer-bottom-sec {
  padding: 15px 0;
  }
  
  .footer-bottom-sec p {
  color: #999999;
  font-size: 12px;
  margin: 0;
  font-weight:400
  }
  .footer-bottom-sec .right_text{
      text-align: right;
  }
  .footer-bottom-sec a:hover{
      color: #fff;
  }
  .copy-right span,
  .copy-right span a{
  color: #4cc706;
  text-transform: capitalize;
  }
  /* The End */


  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    height: 650px;
  }
  
  .each-slide div {
    padding: 20px;
    font-size: 20px;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
  }
  .each-slide div h1{
      font-weight: bold;
      margin: 0;
      color: #ffffff;
  }
  .each-slide div h1 span{
      color: #ffdc4e;
  }

  .react-slideshow-container .default-nav{
      padding: 10px;
  }

  .mean-container a.meanmenu-reveal.meanclose{
    right: 0px;
    left: auto;
    text-align: center;
    text-indent: 0px;
    font-size: 18px;
  }
  .mean-container a.meanmenu-reveal span{
    position: relative;
    top: -20px;
  }

  .mean-container a.meanmenu-reveal.meanclose span{
      display: none;
  }

  .d-block{
      display: block;
  }
  .d-none{
      display: none;
  }

  